import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { Formik, Form } from 'formik';
import { object } from 'yup';

import privacyPolicy from '../../../assets/documents/zasady-ochrany-osobnich-udaju.pdf';
import terms from '../../../assets/documents/podminky-sluzby.pdf';

import { AuthContext } from '../../../contexts/AuthContext';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { FormGroup } from '../../shared/form/FormGroup';
import { Page } from '../../../components/shared/Page';
import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { Button } from '../../shared/Button';
import { FormInput } from '../../shared/form/FormInput';
import { ErrorMessage } from '../../shared/form/ErrorMessage';
import { FormGroupError } from '../../shared/form/FormGroupError';
import { onPhoneChange } from '../../../utilities/onPhoneChange';
import { firstName, surname, email, phone } from '../../../validations';
import { FormPhoneInput } from '../../shared/form/FormPhoneInput';
import { useGTMEvent } from '../../../hooks/useGTMEvent';

const ValidationSchema = object().shape({
  firstName,
  surname,
  email,
  phone,
});

export const Registration = () => {
  const { register } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);
  const [error, setError] = useState(null);
  const pushGTMEvent = useGTMEvent({
    event: 'login:registration:continue',
    eventCategory: 'login',
    eventAction: 'click',
    eventLabel: 'login_registration_continue',
  });

  const initialFormValues = {
    firstName: '',
    surname: '',
    email: '',
    phone: '',
  };

  const resetError = () => {
    setError(null);
  };

  const onSubmit = async ({ phone, ...rest }, { setSubmitting }) => {
    try {
      pushGTMEvent();
      await register({ phone: `+420${phone}`, ...rest });
    } catch (error) {
      const { isConflict, isForbidden } = error;

      if (isConflict || isForbidden) {
        setSubmitting(false);
        return setError(error);
      }

      setGlobalError(error);
    }
  };

  return (
    <Layout showLoginButton={false}>
      <Seo title="Registrace" />
      <Page
        title="Abychom mohli spočítat vaše skóre, potřebujeme pár&nbsp;základních informací"
        description="Nejdříve prosím vyplňte své kontaktní údaje a potvrďte, že jste to opravdu vy."
        className="max-w-4xl mx-auto"
      >
        <Formik
          initialValues={initialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
            errors,
            touched,
            values,
          }) => (
            <Form>
              <section className="flex flex-col w-full sm:max-w-sm mx-auto">
                <FormGroup className="mt-0">
                  <FormInput
                    type="text"
                    name="firstName"
                    placeholder="Jméno"
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    data-testid="registration-firstName"
                  />
                  <FormGroupError
                    name="firstName"
                    data-testid="registration-firstName-error"
                  />
                </FormGroup>

                <FormGroup>
                  <FormInput
                    type="text"
                    name="surname"
                    placeholder="Příjmení"
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    data-testid="registration-surname"
                  />
                  <FormGroupError
                    name="surname"
                    data-testid="registration-surname-error"
                  />
                </FormGroup>

                <FormGroup>
                  <FormInput
                    type="email"
                    name="email"
                    placeholder="Mail"
                    errors={errors}
                    touched={touched}
                    onChange={(event) => {
                      resetError();
                      handleChange(event);
                    }}
                    data-testid="registration-email"
                  />
                  <FormGroupError
                    name="email"
                    data-testid="registration-email-error"
                  />
                </FormGroup>

                <FormGroup>
                  <FormPhoneInput
                    type="tel"
                    name="phone"
                    placeholder="Telefonní číslo"
                    prefix="+&nbsp;420"
                    errors={errors}
                    touched={touched}
                    onChange={(event) => {
                      resetError();
                      onPhoneChange(event, handleChange, setFieldValue);
                    }}
                    data-testid="registration-phone"
                  />
                  <FormGroupError
                    name="phone"
                    data-testid="registration-phone-error"
                  />
                </FormGroup>
              </section>

              <p className="mt-6 sm:max-w-3xl mx-auto text-center">
                Pokračováním souhlasíte s registrací, s{' '}
                <a
                  className="underline cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={terms}
                >
                  Podmínkami užívání
                </a>{' '}
                a&nbsp;
                <a
                  className="underline cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={privacyPolicy}
                >
                  Zásadami ochrany osobních údajů
                </a>
                .
              </p>

              <section className="sm:max-w-sm mx-auto">
                {error?.isConflict && (
                  <ErrorMessage data-testid="registration-conflict-error">
                    Vyplněný mail nebo telefon už známe.
                    <Link
                      to="/ucet/prihlaseni/"
                      className="ml-1 underline"
                      data-testid="registration-error-link-to-login"
                    >
                      Přihlaste se
                    </Link>
                    , prosím.
                  </ErrorMessage>
                )}

                {error?.isForbidden && (
                  <ErrorMessage data-testid="registration-conflict-error">
                    Službu zatím testujeme jen s pár uživateli. Velmi brzy bude
                    přístupná i pro vás.
                  </ErrorMessage>
                )}

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting || !dirty || !isValid}
                  className="mt-6 w-full"
                  data-testid="registration-submit"
                >
                  Pokračovat
                </Button>
                <p className="mt-6 text-center">
                  Už máte účet?
                  <Link to="/ucet/prihlaseni/" className="ml-1 underline">
                    Přihlaste se
                  </Link>
                </p>
              </section>
            </Form>
          )}
        </Formik>
      </Page>
    </Layout>
  );
};
